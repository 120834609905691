
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ActionInterface": [
      "BaseActionNode",
      "GenericCarePlanActionNode",
      "InPersonVisitActionNode",
      "PetActionNode",
      "RecommendationCarePlanActionNode",
      "RelatedResourceCarePlanActionNode",
      "ScheduleAppointmentCarePlanActionNode",
      "ViewCarePlanPetActionNode",
      "VirtualVisitActionNode"
    ],
    "ActionNode": [
      "BaseActionNode",
      "GenericCarePlanActionNode",
      "PetActionNode",
      "ScheduleAppointmentCarePlanActionNode",
      "ViewCarePlanPetActionNode"
    ],
    "AddOnInterface": [
      "EmergencyFundAddOnNode",
      "InsuranceAddOnNode",
      "VCPRAddOnNode"
    ],
    "AddOnNode": [
      "EmergencyFundAddOnNode",
      "InsuranceAddOnNode",
      "VCPRAddOnNode"
    ],
    "AddOnStatusInterface": [
      "EmergencyFundStatusNode",
      "InsuranceStatusNode",
      "VCPRStatusNode"
    ],
    "AddOnStatusNode": [
      "EmergencyFundStatusNode",
      "InsuranceStatusNode",
      "VCPRStatusNode"
    ],
    "CarePlanActionInterface": [
      "GenericCarePlanActionNode",
      "InPersonVisitActionNode",
      "RecommendationCarePlanActionNode",
      "RelatedResourceCarePlanActionNode",
      "ScheduleAppointmentCarePlanActionNode",
      "VirtualVisitActionNode"
    ],
    "CarePlanActionNode": [
      "GenericCarePlanActionNode",
      "InPersonVisitActionNode",
      "RecommendationCarePlanActionNode",
      "RelatedResourceCarePlanActionNode",
      "ScheduleAppointmentCarePlanActionNode",
      "VirtualVisitActionNode"
    ],
    "ChoiceUnion": [
      "ChoiceAlgoliaNode",
      "ChoiceStaticOptionsNode",
      "ImageStaticOptionsNode"
    ],
    "ConversationActionInterface": [
      "InPersonVisitActionNode",
      "ViewCarePlanPetActionNode",
      "VirtualVisitActionNode"
    ],
    "CoreProductDetailsNode": [
      "CoreMedicationNode"
    ],
    "DiscountSource": [
      "CouponNode",
      "DefaultPartnerDiscount",
      "PromoCodeNode"
    ],
    "FundingSource": [
      "FullPerksContractNode",
      "SubscriptionNode"
    ],
    "InsurancePolicyInterface": [
      "EmergencyFundPolicyNode",
      "PetInsurancePolicyNode"
    ],
    "InsurancePolicyNode": [
      "EmergencyFundPolicyNode",
      "PetInsurancePolicyNode"
    ],
    "NextResponse": [
      "QuestionGroupResponse",
      "RedirectResponse"
    ],
    "Node": [
      "AllergyNode",
      "ArticleRecommendationNode",
      "AutoshipPlanNode",
      "BaseActionNode",
      "BillingPlanNode",
      "BooleanPetDetailNode",
      "CardPaymentMethod",
      "CarePlanActionComplianceStatusRecordNode",
      "CarePlanActionFeedbackNode",
      "CarePlanNode",
      "CartNode",
      "CartNodeV2",
      "ChoicePetDetailNode",
      "ClaimAttachmentNode",
      "ClaimRequestNode",
      "ClinicLocationNode",
      "CollectionNode",
      "ConditionNode",
      "CoreClinicNode",
      "CoreIngredientLineNode",
      "CoreIngredientNode",
      "CoreMedicationNode",
      "CorePrescriptionNode",
      "CouponNode",
      "DatePetDetailNode",
      "DeviceNode",
      "DiagnosticAnswerNode",
      "DiagnosticQuestionNode",
      "DiagnosticQuestionTargetValuesNode",
      "EmailTemplate",
      "EmergencyFundAddOnNode",
      "EmergencyFundPolicyNode",
      "EmergencyFundStatusNode",
      "ExternalLinkRecommendationNode",
      "FlatPetDetailNode",
      "FormResponseNode",
      "FormResponseType",
      "FulfillmentNode",
      "FullPerksContractNode",
      "GenericCarePlanActionNode",
      "GiftMembershipNode",
      "GiftMembershipTemplateNode",
      "ImagePetDetailNode",
      "InPersonVisitActionNode",
      "InPersonVisitResultNode",
      "InsuranceAddOnNode",
      "InsurancePolicyDocumentNode",
      "InsuranceQuoteNode",
      "InsuranceStatusNode",
      "InvoiceNode",
      "MedicalRecordNode",
      "MedicationCategoryNode",
      "MedicationDosageNode",
      "MedicationNode",
      "ModelPetDetailNode",
      "OrderInvoiceNode",
      "OrderLineNode",
      "OrderNode",
      "OrderV2Node",
      "PartnerNode",
      "PayPalPaymentMethod",
      "PaymentNode",
      "PeriodicCheckInNode",
      "PetActionNode",
      "PetDetailNode",
      "PetInsurancePolicyNode",
      "PetSymptomCheckerResultType",
      "PlanSubscriptionPrice",
      "PrescriptionNode",
      "PrescriptionRequestNode",
      "PriceNode",
      "ProductNode",
      "ProductRecommendationNodeV2",
      "ProductVariantNode",
      "ProductVariantSubscriptionPriceNode",
      "ProfessionalNode",
      "PromoCodeNode",
      "PurchasedGiftMembershipNode",
      "RecommendationCarePlanActionNode",
      "Refund",
      "RelatedResourceCarePlanActionNode",
      "SMSTemplate",
      "ScheduleAppointmentCarePlanActionNode",
      "SubscriptionLineNode",
      "SubscriptionNode",
      "SupplierNode",
      "SymptomCheckerResponseType",
      "TextPetDetailNode",
      "UserPartnerNode",
      "UserPlanFundingSourceNode",
      "UserPlanNode",
      "UserPlanStatusRecordNode",
      "UserSatisfactionSurveyNode",
      "VCPRAddOnNode",
      "VCPRStatusNode",
      "ViewCarePlanPetActionNode",
      "VirtualVisitActionNode"
    ],
    "PaymentMethodInterface": [
      "CardPaymentMethod",
      "PayPalPaymentMethod"
    ],
    "PaymentMethodNode": [
      "CardPaymentMethod",
      "PayPalPaymentMethod"
    ],
    "PetDetailInterface": [
      "BooleanPetDetailNode",
      "ChoicePetDetailNode",
      "DatePetDetailNode",
      "FlatPetDetailNode",
      "ImagePetDetailNode",
      "ModelPetDetailNode",
      "TextPetDetailNode"
    ],
    "PetDetailNodeV2": [
      "BooleanPetDetailNode",
      "ChoicePetDetailNode",
      "DatePetDetailNode",
      "FlatPetDetailNode",
      "ImagePetDetailNode",
      "ModelPetDetailNode",
      "TextPetDetailNode"
    ],
    "PetTodoInterface": [
      "InPersonVisitActionNode",
      "PetActionNode",
      "ViewCarePlanPetActionNode",
      "VirtualVisitActionNode"
    ],
    "PrescriptionRequestResult": [],
    "RangeValidatorNode": [
      "RangeCountValidatorNode",
      "RangeDateValidatorNode",
      "RangeDatetimeValidatorNode",
      "RangeDecimalValidatorNode",
      "RangeIntegerValidatorNode"
    ],
    "RecommendationNode": [
      "ArticleRecommendationNode",
      "ExternalLinkRecommendationNode",
      "ProductRecommendationNodeV2"
    ],
    "TemplateInterface": [
      "EmailTemplate",
      "SMSTemplate"
    ],
    "ValidatorNode": [
      "RangeCountValidatorNode",
      "RangeDateValidatorNode",
      "RangeDatetimeValidatorNode",
      "RangeDecimalValidatorNode",
      "RangeIntegerValidatorNode"
    ],
    "VisitInterface": [
      "ConversationType",
      "GenericCarePlanActionNode",
      "InPersonVisitActionNode",
      "PeriodicCheckInNode",
      "VirtualVisitActionNode"
    ]
  }
};
      export default result;
    